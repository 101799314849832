import {
  Button,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import React from "react"
import Layout from "../components/Layout"

const useStyles = makeStyles(theme => ({
  sectionSeperator: {
    marginBottom: "8rem",
  },
  sectionSeperatorShadowed: {
    borderRadius: "100%",
    margin: "6rem auto 8rem",
    boxShadow: "0 14px 0px -6px #660000",
    width: "40%",
    height: "1.4rem",
  },
  title: {
    fontSize: "3.2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
    },
  },
  paragraph: {
    fontSize: "1.8rem",
    lineHeight: "2.4rem",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "2rem",
      fontSize: "1.6rem",
    },
  },
  list: {
    fontSize: "1.8rem",
    listStyle: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.6rem",
    },
    "& li": {
      lineHeight: "2.4rem",
      marginRight: "2rem",
      marginBottom: ".5rem",
      "& span": {
        width: "1rem",
        height: "1rem",
        backgroundColor: "#ff3300",
        display: "inline-block",
        borderRadius: "100%",
        marginLeft: ".5rem",
      },
    },
  },
}))

const Pcidss_services = () => {
  const pageMeta = {
    title: "PCIDSS",
    description: "خدمة تطبيق معيار امن صناعة بطاقات الدفع الائتمانية PCIDSS",
    keywords: [
      "أمن المعلومات",
      "حماية أمنية",
      "خدمات أمنية",
      "شهادات أمنية معتمدة",
      "دفع الكتروني",
      "دفع آمن",
      "PCIDSS",
    ],
  }

  const classes = useStyles()
  const matches600 = useMediaQuery("(max-width: 700px)")

  return (
    <Layout isHero pageMeta={pageMeta}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h1" paragraph className={classes.title}>
            معيار أمن بيانات صناعة بطاقات الدفع الائتمانية
          </Typography>
          <Typography className={classes.paragraph} paragraph>
            معيار أمن بيانات صناعة بطاقة الدفع الإئتمانية (PCI DSS) هو معيار صمم
            لدعم وتعزيز حماية بيانات حاملي البطاقات الإئتمانية وضمان الإلتزام
            الدولي بمقياس موحد لتقيم ذلك.
            <br /> <br />
            يعمل الإلتزام بالمعيار على تزويد المؤسسات المالية، مقدمي الخدمات
            وجميع الاطراف المشتركة في التعامل مع بيانات حامل البطاقة بداية من
            نقاط البيع، التاجر،العملاء الماليين، المصدرين، مقدمي الخدمة والمصرف
            المشتري بالمبادئ الاساسية للمتطلبات التشغيلية والتقنية المصممة
            لحماية بيانات الحسابات الخاصة بحاملي البطاقات.
            <br /> <br />
            كما ينطبق المعيار على جميع الهيئات الأخرى التي تعمل على تخزين،
            معالجة أو نقل بيانات حاملي البطاقات (CHD) و/أو بينات المصادقة
            الحساسة (SAD).
            <br /> <br />
            لتلبية متطلبات المعيار سيلزم المؤسسة الإلتزام بعدد ستة متطلبات
            أساسية يقع تحتها إثنى عشر متطلب فرعي.
            <br /> <br />
            تنطبق متطلبات المعيار على جميع المكونات المشاركة او المتصلة بالبنية
            التحتية المستخدمة للتعامل مع بيانات حاملي البطاقة والتي تضم كلا من
            المستخدمين، العمليات والتقنيات التي تستخدم لتخزين، التعامل مع او
            إرسال بيانات حاملي البطاقات او بيانات المصادقة الحساسة . مكونات
            النظام تشمل كلا من معدات الشبكة، الخوادم و التطبيقات.
            <br /> <br />
          </Typography>
          <ul className={classes.list}>
            <li>
              <span className={classes.listItemBullet} />
              الانظمة المزودة للخدمات الأمنية الخاصة بحاملي البطاقات ( مثال ذلك،
              خوادم المصادقة)، او تسهل التجزئة ( مثال ذلك، الجدران النارية
              الداخلية)، او التي توثر على الأمن (مثال ذلك، خوادم اعادة توجيه
              الويب).
            </li>
            <li>
              <span className={classes.listItemBullet} />
              المكونات الإفتراضية مثل الحواسيب الافتراضية، الموجهات والمفاتيح
              الإفتراضية، المعدات الإفتراضية، التطبيقات والاجهزة المكتبية
              الإفتراضية، ومراقب الاجهزة الافتراضية.
            </li>
            <li>
              <span className={classes.listItemBullet} />
              مكونات الشبكة بما في ذلك ولا يقتصر على الجدران النارية، المحولات
              والموجهات، نقاط الوصول اللاسلكية، معدات الشبكة وغيرها من الاجهزة
              الأمنية
            </li>
            <li>
              <span className={classes.listItemBullet} />
              أنواع الخوادم بما في ذلك ولا يقتصر على الويب، التطبيقات، قواعد
              البيانات،المصادق، البريد الالكتروني، البروكسي، برتوكول وقت الشبكة
              ، نظام أسماء النطاقات.
            </li>
            <li>
              <span className={classes.listItemBullet} />
              التطبيقات بما في ذلك التطبيقات الخاصة او المعروضة للبيع،بما في ذلك
              التطبيقات الداخلية والخارجية (مثال ذلك الانترنت).
            </li>
            <li>
              <span className={classes.listItemBullet} />
              أي مكون أو جهاز موجود او متصل مع بيئة بيانات حاملي البطاقات.
            </li>
          </ul>
        </Grid>
        <Grid item className={classes.sectionSeperator} />
        <Grid item container justify={matches600 ? "center" : "flex-start"}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              component="a"
              href="/contact"
            >
              تواصل معنا
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              component="a"
              href="/services"
            >
              عرض كل الخدمات
            </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.sectionSeperator} />
        <Grid item className={classes.sectionSeperator} />
      </Grid>
    </Layout>
  )
}

export default Pcidss_services
